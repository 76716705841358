import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Img,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
// import pen from '../../assets/pen_size.png';
import arrowdown from '../../assets/arrow_down.png';
import arrowup from '../../assets/arrow_drop_up.png';
// import coinlog1 from '../../assets/Coin Logo.png';
// import coinlogo2 from '../../assets/Coin Logo2.png';
// import coinlogo3 from '../../assets/Coin Logo3.png';
// import coinlogo4 from '../../assets/Coin Logo4.png';
// import coinlogo5 from '../../assets/Coin Logo5.png';
import { ClientInfoResponse } from '../../interfaces/components';

interface Payout {
  name: string;
  symbol: string;
  price: string;
  sevenDayChange: string;
  balance: string;
  value: string;
  imageUrl: string;
}

const Payout: React.FC<{ clientInfo: ClientInfoResponse | undefined }> = ({
  clientInfo,
}) => {
  // const dummyData = [
  //   {
  //     name: 'Commercium',
  //     symbol: 'CMM',
  //     price: '50,000',
  //     sevenDayChange: '50',
  //     balance: '50,000',
  //     value: '25,000',
  //     imageUrl: coinlog1,
  //   },
  //   {
  //     name: 'Bitcoin',
  //     symbol: 'BTC',
  //     price: '45,000',
  //     sevenDayChange: '12',
  //     balance: '30,000',
  //     value: '15,000',
  //     imageUrl: coinlogo2,
  //   },
  //   {
  //     name: 'EnergyWeb',
  //     symbol: 'WEBEE',
  //     price: '3,000',
  //     sevenDayChange: '8',
  //     balance: '20,000',
  //     value: '10,000',
  //     imageUrl: coinlogo3,
  //   },
  //   {
  //     name: 'TokenBox',
  //     symbol: 'TBX',
  //     price: '2,000',
  //     sevenDayChange: '5',
  //     balance: '15,000',
  //     value: '8,000',
  //     imageUrl: coinlogo4,
  //   },
  //   {
  //     name: 'Centrality',
  //     symbol: 'CENZZ',
  //     price: '180',
  //     sevenDayChange: '150',
  //     balance: '25,000',
  //     value: '12,000',
  //     imageUrl: coinlogo5,
  //   },

  //   {
  //     name: 'Bitcoin',
  //     symbol: 'BTC',
  //     price: '45,000',
  //     sevenDayChange: '12,000',
  //     balance: '30,000',
  //     value: '15,000',
  //     imageUrl: coinlogo2,
  //   },
  //   {
  //     name: 'EnergyWeb',
  //     symbol: 'WEBEE',
  //     price: '3,000',
  //     sevenDayChange: '8,000',
  //     balance: '20,000',
  //     value: '10,000',
  //     imageUrl: coinlogo3,
  //   },

  //   {
  //     name: 'Centrality',
  //     symbol: 'CENZZ',
  //     price: '180',
  //     sevenDayChange: '15,000',
  //     balance: '25,000',
  //     value: '12,000',
  //     imageUrl: coinlogo5,
  //   },
  //   {
  //     name: 'Commercium',
  //     symbol: 'CMM',
  //     price: '50,000',
  //     sevenDayChange: '50,000',
  //     balance: '50,000',
  //     value: '25,000',
  //     imageUrl: coinlog1,
  //   },
  //   {
  //     name: 'Bitcoin',
  //     symbol: 'BTC',
  //     price: '45,000',
  //     sevenDayChange: '12,000',
  //     balance: '30,000',
  //     value: '15,000',
  //     imageUrl: coinlogo2,
  //   },
  //   {
  //     name: 'TokenBox',
  //     symbol: 'TBX',
  //     price: '2,000',
  //     sevenDayChange: '5,000',
  //     balance: '15,000',
  //     value: '8,000',
  //     imageUrl: coinlogo4,
  //   },
  //   {
  //     name: 'EnergyWeb',
  //     symbol: 'WEBEE',
  //     price: '3,000',
  //     sevenDayChange: '8,000',
  //     balance: '20,000',
  //     value: '10,000',
  //     imageUrl: coinlogo3,
  //   },
  //   {
  //     name: 'TokenBox',
  //     symbol: 'TBX',
  //     price: '2,000',
  //     sevenDayChange: '5,000',
  //     balance: '15,000',
  //     value: '8,000',
  //     imageUrl: coinlogo4,
  //   },
  //   {
  //     name: 'Centrality',
  //     symbol: 'CENZZ',
  //     price: '180',
  //     sevenDayChange: '15,',
  //     balance: '25,000',
  //     value: '12,000',
  //     imageUrl: coinlogo5,
  //   },
  //   {
  //     name: 'Commercium',
  //     symbol: 'CMM',
  //     price: '50,000',
  //     sevenDayChange: '50',
  //     balance: '50,000',
  //     value: '25,000',
  //     imageUrl: coinlog1,
  //   },
  //   {
  //     name: 'Bitcoin',
  //     symbol: 'BTC',
  //     price: '45,000',
  //     sevenDayChange: '12,000',
  //     balance: '30,000',
  //     value: '15,000',
  //     imageUrl: coinlogo2,
  //   },
  //   {
  //     name: 'EnergyWeb',
  //     symbol: 'WEBEE',
  //     price: '3,000',
  //     sevenDayChange: '8,000',
  //     balance: '20,000',
  //     value: '10,000',
  //     imageUrl: coinlogo3,
  //   },
  //   {
  //     name: 'TokenBox',
  //     symbol: 'TBX',
  //     price: '2,000',
  //     sevenDayChange: '5,000',
  //     balance: '15,000',
  //     value: '8,000',
  //     imageUrl: coinlogo4,
  //   },
  //   {
  //     name: 'Centrality',
  //     symbol: 'CENZZ',
  //     price: '180',
  //     sevenDayChange: '15,000',
  //     balance: '25,000',
  //     value: '12,000',
  //     imageUrl: coinlogo5,
  //   },
  // ];
  console.log(clientInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const totalPages = Math.ceil(clientInfo?.results?.length || 0 / rowsPerPage);

  const paginatedData = clientInfo?.results.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const items = [];

    const PageButton = ({ page }: { page: number }) => (
      <IconButton
        key={page}
        aria-label={`Page ${page}`}
        icon={
          <Text color={currentPage === page ? 'white' : '#8C8C8C'}>{page}</Text>
        }
        onClick={() => handlePageChange(page)}
        isActive={currentPage === page}
        variant="ghost"
        bg={currentPage === page ? '#0FBF95' : 'transparent'}
        _hover={{
          bg: currentPage === page ? '#0FBF95' : 'gray.100',
          borderRadius: '100px',
        }}
        _active={{
          bg: '#0FBF95',
          borderRadius: '100px',
        }}
        transition="all 0.2s"
      />
    );

    // Always show first page
    items.push(<PageButton page={1} />);
    items.push(<PageButton page={2} />);

    // Show dots after first page if necessary
    if (currentPage > 3) {
      items.push(
        <Text key="dots1" color="#8C8C8C" fontSize="sm" px={2}>
          ...
        </Text>,
      );
    }

    // Show current page if it's not first or last
    if (currentPage !== 1 && currentPage !== 2 && currentPage !== totalPages) {
      items.push(<PageButton page={currentPage} />);
    }

    // Show dots before last page if necessary
    if (currentPage < totalPages - 2) {
      items.push(
        <Text key="dots2" color="#8C8C8C" fontSize="sm" px={2}>
          ...
        </Text>,
      );
    }

    // Always show last page if there is more than one page
    if (totalPages > 1) {
      items.push(<PageButton page={totalPages} />);
    }

    return items;
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={['24px', '24px', '24px', '21px']}
        background={'rgba(255, 255, 255, 1)'}
        borderRadius={['0px ', '0px', '44px']}
        boxShadow={[
          'none',
          'none',
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
        ]}
        border={['none', 'none', '1px solid rgba(255, 255, 255, 1)']}
        padding={['16px', '16px', '16px', '22px']}

        // borderTop={['1px solid rgba(255, 255, 255, 1)']}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <Text
            fontSize={'24px'}
            fontWeight={'600'}
            lineHeight={'normal'}
            fontFamily="Montserrat"
            color={'#1A1A1A'}
          >
            Assets
          </Text>
        </Box>
        <Box overflow={'auto'}>
          <Table variant="unstyled" minWidth={'630px'}>
            <Thead mb="16px" display={'block'}>
              <Tr
                borderRadius={'10px'}
                border={'solid 0.5px #E2E2E2'}
                background={'#F6F6F6'}
                display="flex"
                justifyContent="space-between"
              >
                <Th
                  fontFamily="Montserrat"
                  color={'#1A1A1A'}
                  fontSize={'13px'}
                  fontWeight={'600'}
                  lineHeight={'20px'}
                  padding={'6px 12px'}
                  flex={['1']}
                >
                  <Text borderRight={'0.5px solid #E2E2E2'}>Name </Text>
                </Th>
                <Flex flex={['1']}>
                  <Th
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 12px'}
                    flex="1"
                    textAlign={'right'}
                  >
                    <Text>Price </Text>
                  </Th>
                  <Th
                    border="none"
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 14px'}
                    flex="1"
                    textAlign={'right'}
                  >
                    <Text borderLeft={'0.5px solid #E2E2E2'}>7d%</Text>
                  </Th>
                  <Th
                    border="none"
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 14px'}
                    flex="1"
                    textAlign={'right'}
                    whiteSpace={'nowrap'}
                  >
                    <Text borderLeft={'0.5px solid #E2E2E2'}>Balance</Text>
                  </Th>
                  <Th
                    border="none"
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 14px'}
                    flex="1"
                    textAlign={'right'}
                    whiteSpace={'nowrap'}
                  >
                    <Text borderLeft={'0.5px solid #E2E2E2'}>Value</Text>
                  </Th>
                </Flex>
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData?.map((item, index) => (
                <Tr
                  key={index}
                  borderBottom={'0.5px solid #F2F2F2'}
                  mb={'20px'}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Td border="none" padding="14px" flex="1">
                    <Flex
                      alignItems={'center'}
                      gap={'8px'}
                      width={['fit-content', 'fit-content', 'none']}
                    >
                      <Img
                        src={item.client.token_img}
                        alt=""
                        height={'36px'}
                        width={'36px'}
                      />
                      <Text
                        fontSize={['13px', '13px', '16px']}
                        fontWeight={'600'}
                        lineHeight={'normal'}
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                      >
                        {item.client.name}
                        {'    '}
                        <span
                          style={{
                            fontFamily: 'Montserrat',
                            color: '#A6A6A6',
                            fontSize: '13px',
                            fontWeight: '500',
                          }}
                        >
                          {item.client.token_symbol}
                        </span>
                      </Text>
                    </Flex>
                  </Td>
                  <Flex flex={['1']}>
                    <Td
                      border="none"
                      padding="14px"
                      flex="1"
                      textAlign={'right'}
                      fontSize={['13px', '13px', '16px']}
                    >
                      ${item.total_market_price}
                    </Td>
                    <Td border="none" padding="14px" flex="1">
                      <Flex flexDirection={'row'} justifyContent={'flex-end'}>
                        <img
                          src={
                            parseFloat(
                              item.client.token_percentage_changes ?? '0',
                            ) < 0
                              ? arrowdown
                              : arrowup
                          }
                          alt=""
                          width="20px"
                          height="20px"
                        />
                        <Text
                          color={
                            parseFloat(
                              item.client.token_percentage_changes ?? '0',
                            ) < 0
                              ? '#FF4D4F'
                              : '#0FBF95'
                          }
                          fontSize="13px"
                          fontWeight="500"
                          lineHeight="20px"
                          fontFamily="Montserrat"
                        >
                          {parseFloat(
                            item.client.token_percentage_changes ?? '0',
                          ).toFixed(4)}
                          %
                        </Text>
                      </Flex>
                    </Td>
                    <Td
                      border="none"
                      padding="14px"
                      flex="1"
                      textAlign={'right'}
                    >
                      {item.total_tokens}
                    </Td>
                    <Td border="none" padding="14px" flex="1">
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        $
                        {(item.total_market_price * item.total_tokens).toFixed(
                          2,
                        )}
                      </Box>
                    </Td>
                  </Flex>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <HStack
        spacing="6px"
        padding={'16px'}
        borderRadius={'44px'}
        border={'1px solid #EFEFEF'}
        background={'#FFF'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        width={'fit-content'}
        alignSelf={'center'}
        marginTop={['0px', '0px', '20px']}
      >
        <IconButton
          aria-label="Previous page"
          icon={
            <ChevronLeftIcon
              color={currentPage <= 1 ? '#8C8C8C' : 'black'}
              height={'24px'}
              width={'24px'}
            />
          }
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage <= 1}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: '100px',
          }}
          _active={{
            bg: 'gray.200',
            borderRadius: '100px',
          }}
          borderRadius="100px"
          transition="all 0.2s"
        />

        {renderPageNumbers()}

        <IconButton
          aria-label="Next page"
          icon={
            <ChevronRightIcon
              color={currentPage >= totalPages ? '#8C8C8C' : 'black'}
              height={'24px'}
              width={'24px'}
            />
          }
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: '100px',
          }}
          _active={{
            bg: 'gray.200',
            borderRadius: '100px',
          }}
          borderRadius="100px"
          transition="all 0.2s"
        />
      </HStack>
    </>
  );
};

export default Payout;
