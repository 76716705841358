import { Box, Flex, Icon, Text, useBreakpointValue } from '@chakra-ui/react';
import { BanknoteIcon, HomeIcon, Users2Icon, WalletIcon } from 'lucide-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavItem {
  href: string;
  label: string;
  icon: React.ElementType;
}

const navItems: NavItem[] = [
  { href: '/', label: 'Dashboard', icon: HomeIcon },
  { href: '/Portfolio-Tracker', label: 'Portfolio Tracker', icon: Users2Icon },
  { href: '/upcoming-payouts', label: 'Upcoming Payouts', icon: WalletIcon },
  { href: '/liquidation-tracker', label: 'Liquidation', icon: BanknoteIcon },
];

export function BottomNav() {
  const location = useLocation();
  // Determine visibility based on screen size
  const isVisible = useBreakpointValue({ base: true, md: true, lg: false });

  // Hide the BottomNav on large screens
  if (!isVisible) return null;

  return (
    <Box
      // display={['flex', 'flex', 'none']}
      position="fixed"
      bottom="0"
      left="50%"
      transform="translateX(-50%)"
      bg="background"
      borderTop="1px solid"
      borderColor="gray.200"
      w="370px"
    >
      <Flex justify="space-around" align="center" h="16">
        {navItems.map(({ href, label, icon }) => {
          const isSelected = location.pathname === href;
          return (
            <Link key={href} to={href}>
              <Flex
                direction="column"
                align="center"
                justify="center"
                flex="1"
                h="full"
                color={isSelected ? '#0FBF95' : 'muted'}
                _hover={{ color: '#0FBF95' }}
                transition="color 0.2s"
              >
                <Icon as={icon} boxSize={5} />
                <Text fontSize="xs" mt="1">
                  {label}
                </Text>
              </Flex>
            </Link>
          );
        })}
      </Flex>
    </Box>
  );
}
