import { Box, Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { ChevronLeft, User } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavbarOption } from '../../interfaces/components';
import Drawer from '../drawer/Drawer';
import { BottomNav } from '../header/BottomNav';
import Header from '../header/Header';

export interface OverlayProps {
  children: React.ReactNode;
}

export const navbarOptions: NavbarOption[] = [
  {
    href: '/',
    key: 'dashboard',
    text: 'Dashboard',
  },
  // {
  //   href: '/investments',
  //   key: 'investments',
  //   text: 'Investment tracker',
  // },
  {
    href: '/Portfolio-Tracker',
    key: 'Portfolio Tracker',
    text: 'Portfolio Tracker',
  },
  {
    href: '/upcoming-payouts',
    key: 'payouts',
    text: 'Upcoming payouts',
  },
  {
    href: '/liquidation-tracker',
    key: 'liquidation',
    text: 'Liquidation',
  },
];

function Overlay({ children }: OverlayProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [activeView, setActiveView] = useState('payouts');

  useEffect(() => {
    const activeSidebarOption = navbarOptions.find(
      (navbarOption: NavbarOption) => navbarOption.href === location.pathname,
    );

    activeSidebarOption
      ? setActiveView(activeSidebarOption.key)
      : setActiveView('');
  }, [location.pathname]);

  return (
    <Box
      w="100%"
      h="100vh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Header
        activeView={activeView}
        navbarOptions={navbarOptions}
        openDrawer={onOpen}
      />
      <Drawer
        activeView={activeView}
        navbarOptions={navbarOptions}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Box
        display="flex"
        h={[
          'calc(100vh - 54px)',
          'calc(100vh - 54px)',
          'calc(100vh - 54px)',
          'calc(100vh - 65px)',
          'calc(100vh - 65px)',
        ]}
        background={['white', 'white', '#F7F7F7']}
        flexDirection="column"
      >
        <Box
          display={['flex', 'flex', 'none', 'none', 'none']}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding={'12px 16px'}
          fontFamily="Montserrat"
          fontWeight="600"
          fontSize="18px"
          bg="white"
          color="#1A1A1A"
          border={'transparent'}
          borderBottom="1px solid #E2E2E2"
        >
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <IconButton
              icon={<ChevronLeft size="24px" />}
              aria-label="Back"
              variant="ghost"
              color="gray.500"
            />
            <Box>
              {navbarOptions?.find(
                (navbarOption: NavbarOption) =>
                  navbarOption.href === location.pathname,
              )?.text || 'Dashboard'}
            </Box>
          </Box>
          <Button
            color="gray.500"
            p="2"
            border="1px solid"
            borderColor="#E2E2E2"
            borderRadius="0.8rem"
            variant="outline"
          >
            <User size="24px" />
          </Button>
        </Box>

        <Box
          margin="0 auto"
          width="100%"
          height="100%"
          maxWidth="1440px"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': { width: '0px', height: '0px' },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }}
          padding={['0px 5px 5px 5px ', '0px 5px 5px 5px', '20px']}
        >
          {children}
        </Box>
      </Box>
      <BottomNav />
    </Box>
  );
}

export default Overlay;
